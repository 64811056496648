import { Button, Tooltip } from 'antd';

import { PhoneOutlined } from '@ant-design/icons';
import { isNowOpen } from '../utils/getWorkingHours';

export default function ButtonRdv(props: { type: "primary" | "default" | "dashed" | "link" | "text" }) {
    return (
        <Tooltip title={isNowOpen() ? "Actuellement ouvert" : "Actuellement fermé"}>
            <Button type={props.type} icon={<PhoneOutlined />}>
                <a href="tel:+32460214112">
                    Prendre un rendez-vous
                </a>
            </Button>
        </Tooltip>
    )
}
